<template>
    <div :id="blok._uid"
        class="mx-sm space-y-2xl md:mx-md 2xl:mx-0">
        <div class="grid grid-cols-2 gap-sm md:grid-cols-3">
            <sb-article-recommended v-for="(article, index) in articles"
                :key="index"
                :blok="article"
                :article="article.uuid" />
        </div>

        <div class="flex items-center justify-center">
            <nuxt-link v-if="url !== undefined"
                :to="blok.button_link.url ? '/' + blok.button_link.url : '/' + blok.button_link.cached_url "
                :aria-label="blok.button_text"
                class="mx-auto mt-xl w-full text-center">
                <v-button :aria-label="blok.button_text"
                    size="wide"
                    theme="black"
                    class="w-auto">
                    {{ blok.button_text }}
                </v-button>
            </nuxt-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BaseBlokComponent } from '~~/types';

const properties = defineProps<{
    blok: BaseBlokComponent
}>();

const articleUuids = properties.blok.articles?.join(',');
const { format: formatSbUrl } = useSbUrl();

const url = computed(() => formatSbUrl(properties.blok.button_link?.cached_url));

const articles = await useAsyncStoryblokStories('articles-feed-pages', {
    /* eslint-disable @typescript-eslint/naming-convention */
    by_uuids: articleUuids,
    is_startpage: false,
    per_page: 6,
    sort_by: 'first_published_at:desc',
    /* eslint-enable @typescript-eslint/naming-convention */
});

// const { data } = useStoryblokStories('articles-feed-pages', {
//     /* eslint-disable @typescript-eslint/naming-convention */
//     by_uuids: articleUuids,
//     /* eslint-enable @typescript-eslint/naming-convention */
// });
</script>
